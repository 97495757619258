import './sales-force-btwog.scss';

console.log('sales force b2g');

class SalesForceBtwog {
    constructor (element) {
        this.$salesForceForm = element;
        this.$requiredFields = this.$salesForceForm.querySelectorAll('[required]');
        this.$filledFields = null;
        this.$submitButton = this.$salesForceForm.querySelectorAll('[data-sales-force-b2g="submit"]')[0];
    }

    init () {
        this.submitForm();
    }

    checkRequiredFields () {
        this.$filledFields = [];
        for (let index = 0; index < this.$requiredFields.length; index++) {
            const inputValue = this.$requiredFields[index].value;

            if (inputValue !== '') {
                this.$filledFields.push(this.$requiredFields[index]);
                this.$requiredFields[index].parentElement.classList.remove('has--error');
            } else {
                this.$requiredFields[index].parentElement.classList.add('has--error');
            }
        }
        console.log(this.$filledFields);
    }

    submitForm () {
        console.log(this.$submitButton);
        this.$submitButton.addEventListener('click', (e) => {
            this.checkRequiredFields();

            if (this.$filledFields.length !== this.$requiredFields.length) {
                e.preventDefault();
            }
        });
    }
}

export { SalesForceBtwog };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$salesForceB2G = $context.querySelectorAll('[data-sales-force-b2g="root"]');
        for (let i = 0; i < $$salesForceB2G.length; i++) {
            const $salesForceB2G = new SalesForceBtwog($$salesForceB2G[i]);
            $salesForceB2G.init();
        }
    }
});
